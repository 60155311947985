import React, {useCallback, useContext, useMemo, useState} from 'react'
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {isCanonLink} from "../../roundup/RedditPost";
import {getSiteIconForMirror} from "./SiteIcons";
import {BiLinkExternal} from "react-icons/bi";
import {Popup} from 'semantic-ui-react'
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";
import {queueIcon} from "../../../util/Util";
import {RoundupMatcherContext} from "../../roundup/RoundupMatcher";

export default function MusicMirrors(props) {
    let post = props.post;

    return <Row className={"mirror_row"}>
        {props.icon}
        {post.mirrors && post.mirrors.length > 0 && post.mirrors.map(mir => {
            return <Mirror usePopup={props.usePopup} post={post} key={mir.otherLink} mirror={mir}/>
        })}
    </Row>
}
export function Mirror(props) {
    let post = props.post
    let mirror = props.mirror

    let queueContext = useContext(MediaQueueWrapperContext);
    // let matcherContext = useContext(RoundupMatcherContext);
    let [isPopupOpen, setIsPopupOpen] = useState(false)

    let openInNewTab = useCallback((mir) => {
        window.open(mir.otherLink, '_blank').focus()
    }, [])

    let addMirrorToQueue = useCallback((mir, post, suppressToast=false) => {
        let data = {post: post, src: mir.otherLink}
        queueContext.addToQueue(data, suppressToast)
    }, [queueContext])

    let onclick = useCallback((mir) => {
        if (props.usePopup) {
            setIsPopupOpen(!isPopupOpen)
        } else {
            addMirrorToQueue(mir, post)
        }
    }, [addMirrorToQueue, isPopupOpen, props.usePopup])



    let mirrorButton = (
        <Button data-src={""} variant={isCanonLink(mirror, post) ? "primary" : "secondary"}
                onClick={() => onclick(mirror)}>
            {getSiteIconForMirror(mirror)}
        </Button>
    );

    let popupContent = (
        <>
            <Button variant={"secondary"} onClick={() => addMirrorToQueue(mirror, post)}>
                {queueIcon} Queue
            </Button>

            &nbsp;
            <Button data-src={mirror.otherLink} variant={"secondary"} onClick={() => openInNewTab(mirror)}>
                <BiLinkExternal/> Open
            </Button>
            <br/> {mirror?.otherTitle && (<>{mirror.otherTitle}<br/> </>)}
            {mirror.confidence === undefined ? (
                <>
                    Original link
                </>
            ) : (
                <>
                    Confidence: {mirror.confidence}%
                </>
            )}

        </>
    )
    return <div>
        {props.usePopup ? (
            <Popup on="click" position="top left" content={popupContent} trigger={mirrorButton}
                   open={isPopupOpen}
                   onClose={() => setIsPopupOpen(false)}
                   onOpen={() => setIsPopupOpen(true)}
            />
        ) : (
            mirrorButton
        )}
    </div>

}