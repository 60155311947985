import {createContext, useCallback, useContext, useEffect, useState} from "react";
import MediaQueue from "../MediaQueue";
import {useReducerWithSessionStorage} from "../../../util/hooks/useSessionStorage";
import {
    ADD_TO_QUEUE,
    CLEAR_QUEUE,
    DISABLE_PLAY_BUTTON,
    ENABLE_PLAY_BUTTON,
    GO_TO_NEXT_QUEUE_ITEM,
    GO_TO_PREV_QUEUE_ITEM,
    IS_PAUSED,
    IS_PLAYING,
    mediaQueueReducer,
    REMOVE_FROM_QUEUE, REORDER_QUEUE,
    SET_QUEUE_INDEX, SET_VISIBILITY,
    TOGGLE_MUTE,
    TOGGLE_PLAY,
    TOGGLE_SHOW_QUEUE
} from "./mediaQueueReducer";
import {SpotifyConnect} from "../spotify/SpotifyConnect";
import {
    cloneDeep,
    ERROR_TOAST,
    getYoutubeId,
    logger,
    SPOTIFY_ALBUM,
    SPOTIFY_PLAYLIST,
    SPOTIFY_SONG,
    SUCCESS_TOAST
} from "../../../util/Util";
import {getSiteFromUrl} from "../util/SiteIcons";
import {MyToastContext} from "../../../util/context/MyToastContext";
import {VerifiedAccountsContext} from "../../../util/context/VerifiedAccountsContext";
import {toast} from "react-toastify";

export const MediaQueueWrapperContext = createContext({});

const defaultQueue = {
    queue: [],
    isQueueExpanded: true,
    playIndex: 0,
    isMuted: false,
    isPlaying: false,
    isPlayDisabled: false,
    shouldBePlaying: false
}


/**
 * This function modifies the queue data after we retrieve it from session storage
 * @param data
 */
function sessionStorageCallback(data) {
    data.shouldBePlaying = false;
    data.isPlaying = false;
}

export function MediaQueueWrapper(props) {

    let toastContext = useContext(MyToastContext);
    let userContext = useContext(VerifiedAccountsContext);


    let [queueData, queueDispatch] =
        useReducerWithSessionStorage("queue", mediaQueueReducer, defaultQueue)

    let isLastItem = useCallback(() => {
        return queueData.playIndex + 1 === queueData.queue.length
    }, [queueData.playIndex, queueData.queue]);

    let isFirstItem = useCallback(() => {
        return queueData.playIndex === 0;
    }, [queueData.playIndex]);
    let reorderQueue = (newOrder, data2) => {
        var changeDetected = false;
        for (let i in newOrder) {
            let prev = queueData.queue[i]
            let newItem = newOrder[i]
            if (prev.src !== newItem.src) {
                changeDetected = true
            }
        }
        if (changeDetected) {
            // noinspection JSCheckFunctionSignatures
            queueDispatch({type: REORDER_QUEUE, item: newOrder});
        }
    }

    let addToQueue = (media, suppressToast=false) => {
        queueDispatch({type: ADD_TO_QUEUE, queueMedia: media})
        if (!suppressToast) {
            toastContext.addToast("Added to queue", SUCCESS_TOAST, 1000)
        }
    }
    let setVisibility = (isQueueExpanded) => {
        queueDispatch({type: SET_VISIBILITY, payload: isQueueExpanded})
    }

    let removeFromQueue = (media) => {
        queueDispatch({type: REMOVE_FROM_QUEUE, queueMedia: media})
    }

    let clearQueue = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: CLEAR_QUEUE})
    }
    let toggleShowMediaQueue = () => {
        queueDispatch({type: TOGGLE_SHOW_QUEUE});
    }

    let toggleMute = () => {
        queueDispatch({type: TOGGLE_MUTE});
    }

    let goToItemInQueue = (index) => {
        queueDispatch({type: SET_QUEUE_INDEX, payload: index})
    }

    /**
     * This should be called when we know the media is playing
     */
    let setPlayingDisplay = useCallback(() => {
        if (!queueData.isPlaying) {
            // noinspection JSCheckFunctionSignatures
            queueDispatch({type: IS_PLAYING});
        }
    }, [queueData.isPlaying, queueDispatch])

    /**
     * This should be called when we know the media has been paused
     */
    let setPausedDisplay = useCallback(() => {
        if (queueData.isPlaying) {
            // noinspection JSCheckFunctionSignatures
            queueDispatch({type: IS_PAUSED});
        }
    }, [queueData.isPlaying, queueDispatch])

    /**
     * This should be called when the user clicks the play/pause button and we need to tell the media to play/pause
     */
    let togglePlay = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: TOGGLE_PLAY});
    }

    let nextQueueItem = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: GO_TO_NEXT_QUEUE_ITEM});
    }

    let prevQueueItem = () => {
        // noinspection JSCheckFunctionSignatures
        queueDispatch({type: GO_TO_PREV_QUEUE_ITEM});
    }


    let disablePlayButtonToggle = (shouldDisable) => {
        if (queueData.isPlayDisabled && !shouldDisable) {
            // enable
            queueDispatch({type: ENABLE_PLAY_BUTTON});
        } else if (!queueData.isPlayDisabled && shouldDisable) {
            // disable
            queueDispatch({type: DISABLE_PLAY_BUTTON});
        }
    }

    const shouldShowMediaQueue = useCallback(() => {
        let dataExists = queueData?.queue?.length > 0;
        let disallowedPaths = ["/welcome", "/forgotPassword"]
        let isBadPath = disallowedPaths.find((path) => window.location.pathname.includes(path))
        return !isBadPath && dataExists
    }, [queueData])

    const getCurrentItem = useCallback(() => {
        return queueData.queue && queueData.queue.length > 0 ?
            queueData.queue[queueData.playIndex] :
            null
    }, [queueData.playIndex, queueData.queue])

    const getEmbedUrlForCurrentItem = useCallback(() => {
        let queueItem = getCurrentItem()
        if (!queueItem) {
            return null;
        }
        let mediaUrl = queueItem["src"]
        // For spotify: find the `src` param. For all others use `url`
        let firstParam = "url"
        let secondParam = "src"
        if (mediaUrl.includes("open.spotify.com") || mediaUrl.includes("audiomack.com")) {
            firstParam = "src";
            secondParam = "url"
        }

        if (queueItem.post?.custom_embed) {
            let mediaUrlSite = getSiteFromUrl(mediaUrl)
            let customEmbedSite = getSiteFromUrl(queueItem.post?.custom_embed)
            if (mediaUrlSite === customEmbedSite) {
                mediaUrl = queueItem.post.custom_embed
            }
        }

        let url;
        if (queueItem.className === "embedly-embed") {
            url = (new URL(mediaUrl)).searchParams.get(firstParam) || (new URL(mediaUrl)).searchParams.get(secondParam)
        } else {
            url = mediaUrl;
        }

        let urlObj;
        try{
            urlObj =new URL(url)
        }catch(e){}

        if (url.includes("//music.apple.com")) {
            url = url.replace("//music.apple.com", "//embed.music.apple.com")
        } else if (url.includes("youtube.com/watch")) {
            let vid = urlObj.searchParams.get("v")
            let playlist = urlObj.searchParams.get("list")
            let index = urlObj.searchParams.get("index")
            let newUrl = `https://youtube.com/watch?v=${vid}`
            if (playlist && playlist !== "WL") {
                newUrl += "&list=" + playlist
                if (index != null) {
                    newUrl += "&index=" + index
                }
            }
            return newUrl

        } else if (url.includes("twitch.tv/")) {
            let newUrl = ""
            let vidId = ""
            if (url.includes("/clip/")) {
                // src="https://clips.twitch.tv/embed?clip=AmazonianSpoopyTireSuperVinlin-8h-mKxC0HSwucMJd&parent=www.example.com"
                let urlSplit = urlObj.pathname.split("/")
                // newUrl = `https://player.twitch.tv?video=${urlSplit[urlSplit.length-1]}`
                vidId = urlSplit[urlSplit.length-1]
            } else {
                vidId = urlObj.pathname.split("/")[1]
            }
            newUrl = `https://clips.twitch.tv/embed?clip=${vidId}`
            newUrl += "&parent=roundupforreddit.com"
            return newUrl
        } else if (url.includes("v.redd.it")) {
            // return queueItem?.post?.media_embed?.reddit_video?.hls_url || url
            return queueItem?.post?.media_embed?.reddit_video?.dash_url || url
        } else if (url.includes("deezer.com")) {
            let match = mediaUrl.match("/.*deezer.com/(.*)/(.*)")
            if (match && match[1] && match[2]) {
                return `https://widget.deezer.com/widget/auto/${match[1]}/${match[2]}`
            }
            // <iframe title="deezer-widget" src="https://widget.deezer.com/widget/auto/track/70018662" width="100%"
            //         height="300" frameBorder="0" allowTransparency="true"
            //         allow="encrypted-media; clipboard-write"></iframe>
            // url = queueItem?.post?.media_embed?.reddit_video?.hls_url || url
        } else if (url.includes("bandcamp.com")) {
            // https://bandcamp.com/EmbeddedPlayer/track=3108705539/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/
            // https://bandcamp.com/EmbeddedPlayer/album=3227262162/size=small/bgcol=ffffff/linkcol=0687f5/track=2451454808/transparent=true/
            try {
                let itemId, itemType;
                if (url.includes("/EmbeddedPlayer/")) {
                    itemId = url.match(/\/album=(\d+)\//)[1]
                    if (itemId) {
                        itemType = "a"
                    } else {
                        itemId = url.match(/\/track=(\d+)\//)[1]
                        itemType = "t"
                    }
                } else {
                    itemId = new URL(url).searchParams.get("search_item_id")
                    itemType = new URL(url).searchParams.get("search_item_type")
                }
                return `https://bandcamp.com/EmbeddedPlayer/${itemType === "a" ? "album" : "track"}=${itemId}/size=large/artwork=small/tracklist=${itemType === "a"}/bgcol=ffffff/linkcol=0687f5/transparent=true/`
            } catch (ignore) {
                // <iframe style="border: 0; width: 100%; height: 42px;"
                //         src="https://bandcamp.com/EmbeddedPlayer/album=3227262162/size=small/bgcol=ffffff/linkcol=0687f5/track=2451454808/transparent=true/"
                //         seamless><a href="https://tommyrichman.bandcamp.com/album/million-dollar-baby">MILLION DOLLAR
                //     BABY by Tommy Richman</a></iframe>
                logger("Error in bandcamp embed", ignore)
            }
        } else if (url.includes("gfycat.com")) {
            let reg = new RegExp("gfycat.com/ifr/(.*)");
            if (!url.match(reg)) {
                reg = new RegExp("gfycat.com/([^/]*)");
                let match = url.match(reg)
                if (match && match[1]) {
                    return `https://gfycat.com/ifr/${match[1]}`
                } else {
                    logger("bad gfycat url? ", url);
                }
            }
        }
        return url;
    }, [getCurrentItem])


    let [addedListener, setAddedListener] = useState(false)
    let [foundYoutubeError, setFoundYoutubeError] = useState("")
    let [currentYtId, setCurrentYtId] = useState(null)

    let [errorCount, setErrorCount] = useState({})

    let messageListener = useCallback((event) => {
        let source = event?.data?.source
        try {
            if (event.origin.includes("sdk.scdn")) {
                // do nothing
            } else if (event.origin.includes("w.soundcloud.com")) {
                // do nothing
            } else if (source && source.includes("react-devtools")) {
                // do nothing
            } else if ((event?.data?.source && event?.data?.source?.includes("setImmediate"))
                || (event?.data?.includes && event?.data?.includes("setImmediate"))) {
                // do nothing
            } else {
                // do something with the data
                let data;
                try {
                    data = JSON.parse(event.data)
                } catch (e) {
                    data = event.data
                }
                let errCountCopy = cloneDeep(errorCount)

                if (event.origin.includes("youtube.com")) {
                    let queuedId = getYoutubeId(getEmbedUrlForCurrentItem())
                    if (data.event === "onStateChange") {
                        // if the previous event caused an error, this means the error hasn't been fixed
                        errCountCopy = {}
                        logger("state change reset", data)
                    }
                    else if (data?.info?.videoData != null || currentYtId ) {
                        let vid = data?.info?.videoData?.video_id
                        let playlistId = data?.info?.playlistId
                        if (vid) {
                            setCurrentYtId(vid)
                        } else {
                            vid = currentYtId
                        }

                        logger(`Youtube id: [${vid}]`, `queuedId: [${queuedId}]`, "err count", errCountCopy)

                        var foundError = false
                        if (vid && queuedId && queuedId !== vid) {
                            if (queuedId && playlistId && queuedId !== playlistId) {
                                foundError = true
                            }
                        }
                        if (foundError) {
                            if (errCountCopy[queuedId] == null) {
                                errCountCopy[queuedId] = 1
                            } else {
                                errCountCopy[queuedId] += 1
                            }
                            logger("error. count: ", errCountCopy[queuedId], data)
                            if (errCountCopy[queuedId] === 3) {
                                let toast = <>
                                    Error playing this video<br/>
                                    {getCurrentItem()?.post?.title}
                                </>
                                toastContext.addToast(toast, ERROR_TOAST)
                                // setFoundYoutubeError(queuedId)
                            }
                        } else {
                            delete errCountCopy[queuedId]
                            // errCountCopy[queuedId] = 0
                            // setFoundYoutubeError("")
                            logger("no error", data)
                        }
                    } else if (data.event === "infoDelivery" && errCountCopy[queuedId]) {
                        // if the previous event caused an error, this means the error hasn't been fixed
                        errCountCopy[queuedId] += 1
                    }
                    setErrorCount(errCountCopy)
                }
                logger("message event listener:", event.origin, data)
            }
        } catch (e) {
            logger("help", e)
        }

    }, [queueData, queueData.playIndex, errorCount, queueData.queue, getCurrentItem,
        getEmbedUrlForCurrentItem, foundYoutubeError, toastContext, currentYtId])

    useEffect(() => {
        // if (!addedListener) {
        //     setAddedListener(true)
        //     window.addEventListener("message", messageListener, false);
        // }
        window.addEventListener( "message" , messageListener)
        return () => {
            window.removeEventListener("message", messageListener)
        }

    }, [messageListener])

    const getCurrentItemSite = useCallback(() => {
        return getSiteFromUrl(getEmbedUrlForCurrentItem())
    }, [getEmbedUrlForCurrentItem])

    let methods = {
        setVisibility: setVisibility,
        addToQueue: addToQueue,
        getCurrentItem: getCurrentItem,
        getEmbedUrlForCurrentItem: getEmbedUrlForCurrentItem,
        getCurrentItemSite: getCurrentItemSite,
        setPlayingDisplay: setPlayingDisplay,
        setPausedDisplay: setPausedDisplay,
        disablePlayButtonToggle: disablePlayButtonToggle,
        togglePlay: togglePlay,
        toggleShowMediaQueue: toggleShowMediaQueue,
        removeFromQueue: removeFromQueue,
        toggleMute: toggleMute,
        reorderQueue: reorderQueue,
        nextQueueItem: nextQueueItem,
        prevQueueItem: prevQueueItem,
        goToItemInQueue: goToItemInQueue,
        clearQueue: clearQueue,
        isFirstItem: isFirstItem,
        isLastItem: isLastItem,
    }

    return <MediaQueueWrapperContext.Provider value={{
        // this is available to any child of the MediaQueue w/ MediaQueueContext
        ...methods,
        data: queueData,
    }}>
        <SpotifyConnect>
            {/*<DebugInfo/>*/}

            {shouldShowMediaQueue() &&
                <MediaQueue/>
            }
            {props.children}
        </SpotifyConnect>
    </MediaQueueWrapperContext.Provider>
}