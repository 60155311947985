import {FaExpand, FaMinus} from "react-icons/fa";
import {useEffect, useRef} from "react";
import {CommonState, STATE_TYPE} from "../components/media_queue/spotify/SpotifyState";
import {State} from "../components/media_queue/spotify/SpotifyConnectReducer";

import {GrConnect} from "react-icons/gr";
import {MdAddToQueue, MdOutlineErrorOutline, MdOutlineOutlet, MdVerified} from "react-icons/md";
import {BiRefresh} from "react-icons/bi";

export const cloneDeep = require("lodash.clonedeep");
export const MATCHER_DISPLAY_NAME_MAX_LENGTH = 75
export const SORT_BY_COMMENTS = "comments";
export const SORT_BY_UPVOTES = "upvotes";
export const SORT_BY_AWARDS = "awards";
// export const COLLAPSE_ICON = <AiOutlineCompress/>
// export const EXPAND_ICON = <AiOutlineExpand/>
export const COLLAPSE_ICON = <FaMinus/>
export const EXPAND_ICON = <FaExpand/>
export const DELIVER_BY_REDDIT = "REDDIT";
export const DELIVER_BY_EMAIL = "EMAIL";

export const OR_FILTER = "OR";
export const AND_FILTER = "AND";
export const KEYWORD_FILTER = "KEYWORD";

export const INFO_TOAST = "INFO";
export const SUCCESS_TOAST = "SUCCESS";
export const WARNING_TOAST = "WARNING";
export const ERROR_TOAST = "ERROR";

export const IS_DEV = process.env.NODE_ENV !== "production" || process.env.REACT_APP_ENV === "dev"
// export const IS_DEV = process.env.NODE_ENV !== "production"
export const REDDIT_CLIENT_ID = IS_DEV ? "3BUxpKIeMjjzfQ" :  "saicPbRqhCvQoQ"
export const BASE_URL = IS_DEV ? "http://localhost:8080" : "https://roundupforreddit.com"

export const REDDIT_AUTH_URL = `https://www.reddit.com/api/v1/authorize` +
    `?client_id=${REDDIT_CLIENT_ID}` +
    `&response_type=code` +
    `&redirect_uri=${encodeURI(`${BASE_URL}/redditCallback`)}` +
    `&duration=temporary` +
    `&scope=identity%20mysubreddits`;

const SPOTIFY_CLIENT_ID = "95fba103d6594414b60f5ff369c06fe0";
// Keep in sync with SpotifyOauthService.java
const SPOTIFY_SCOPE = "streaming user-read-email user-read-private user-modify-playback-state  " +
    "user-read-playback-state user-read-currently-playing app-remote-control user-top-read user-library-read user-library-modify " +
    "user-read-recently-played"
export const SPOTIFY_AUTH_URL = `https://accounts.spotify.com/authorize` +
    `?client_id=${SPOTIFY_CLIENT_ID}` +
    `&response_type=code` +
    `&redirect_uri=${encodeURI(`${BASE_URL}/spotifySuccess`)}` +
    `&scope=${encodeURI(SPOTIFY_SCOPE)}`;

export const TYPE_SECTION = "section"
export const TYPE_FILTER = "filter"
export const TYPE_COMBO_FILTER = "filter_combo"

export const TIME_VALUES = ["Midnight", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11AM",
    "Noon", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11PM"];
// DEFAULT COMBO FILTER

export function msToTime(s) {

    // 1- Convert to seconds:
    let seconds = s;
    // 2- Extract hours:
    let hours = Math.floor(seconds / 3600);
    // seconds remaining after extracting hours
    seconds = seconds % 3600;
    // 3- Extract minutes:
    let minutes = Math.floor(seconds / 60);
    // 4- Keep only seconds not extracted to minutes:
    seconds = Math.round(seconds % 60);

    // 0-padding
    if (seconds < 10) {
        seconds = "0" + seconds
    }
    if (minutes < 10 && hours > 0) {
        minutes = "0" + minutes
    }

    if (hours) {
        return `${hours}:${minutes}:${seconds}`
    }
    return `${minutes}:${seconds}`
}

export const SPOTIFY_SONG = "song"
export const SPOTIFY_ALBUM = "album"
export const SPOTIFY_PLAYLIST = "playlist"
export function getUriType(uri) {
    if (!uri) {
        return null;
    } else if (uri.includes(":album:")) {
        return SPOTIFY_ALBUM
    } else if (uri.includes(":track:")) {
        return SPOTIFY_SONG
    } else if (uri.includes(":playlist:")) {
        return SPOTIFY_PLAYLIST
    }
}
/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str) {
    let hash = 0;
    for (var i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function getPercentage (position, duration) {
    let percent = Number((position / duration) * 100);
    let label = msToTime(position) + " / " + msToTime(duration)
    return {
        percent: percent,
        label: label
    }
}

export function reducerLog(reducerName, action){
    let rName = `[reducer ${reducerName}]`
    if (action.data) {
        logger(rName + " ~~~ action:", action.type, action.data, "~~~")
    }
    else if (action.media) {
        logger(rName + " ~~~ action:", action.type, action.media, "~~~")
    }
    else if (action.payload) {
        logger(rName + " ~~~ action:", action.type, action.payload, "~~~")
    } else {
        logger(rName + " ~~~ action:", action.type, "~~~")
    }
}

export function shortenAccessToken(token) {
    if (token) {
        return token.substring(0, 10) + " ... " + token.substring(token.length - 10, token.length)
    }
    return token
}

export function logError() {
    let dateStr = new Date().toJSON().replace("Z", "").substring(11)

    var args = Array.prototype.slice.call(arguments);
    args[0] = dateStr + ": " + args[0]
    console.error.apply(console, args);
}

export function logger() {
    if (IS_DEV) {
    // if (true) {
        let dateStr = new Date().toJSON().replace("Z", "").substring(11)

        var args = Array.prototype.slice.call(arguments);
        args[0] = dateStr + ": " + args[0]
        console.log.apply(console, args);
    }
}

export const embed_sites = require('../resources/videoembeds.json');

// url FILTER
const imageFilterObject = createFilterObject(false, false, true,
    false, ["i.redd.it", ".jpg", ".img", ".gif", "imgur.com", "reddit.com/gallery"], false, createNewFilterId(), true);

const streamingFilterObject = createFilterObject(false, false, true,
    false, [ "spotify.com", "music.apple.com", "soundcloud.com","tidal.com", "tidalhifi.com", "youtu.be", "youtube.com"], false, createNewFilterId(), true);

const videoFilterObject = createFilterObject(false, false, true,
    false, [
        "youtube.com",
        "v.redd.it",
        "youtu.be",
        "streamable.com",
        "gfycat",
        "v.reddit",
        "twitch.tv",
        "kick.com",
        "med.nhl.com",
        "nbcsports.com/video",
        "espn.com/video",
        "bdata-producedclips.mlb.com",
        "diamond.mlb.com",
        "clippituser.tv",
        "video.twimg",
        "streamja.com"], false, createNewFilterId(), true);

// flair filter
const video2Object = createFilterObject(false, true, false,
    false, ["video"], false, createNewFilterId(), true);


export function getVideoFilterObject() {
    let filter = cloneDeep(videoFilterObject);
    filter.id = createNewFilterId();
    let filter2 = cloneDeep(video2Object);
    filter2.id = createNewFilterId();

    return createComboFilter([filter, filter2], OR_FILTER);
}
export function getStreamingFilterObject() {
    let filter = cloneDeep(streamingFilterObject);
    filter.id = createNewFilterId();
    return filter;
}
export function getImageFilterObject() {
    let filter = cloneDeep(imageFilterObject);
    filter.id = createNewFilterId();
    return filter;
}

const defaultComboFilterObject = createComboFilter([], AND_FILTER);
export function getDefaultComboFilterObject() {
    let filter = cloneDeep(defaultComboFilterObject);
    filter.id = createNewFilterId();
    return filter;
}

// DEFAULT FILTER
const defaultFilterObject = createFilterObject(true, false, false,
    false, [""], false, createNewFilterId(), true);

export function getDefaultFilterObject() {
    let filter = cloneDeep(defaultFilterObject);
    filter.id = createNewFilterId();
    filter.isPremade = true;
    return filter;
}


// DEFAULT SECTION
const defaultSectionObject = createSectionObject(
    true, SORT_BY_UPVOTES, [], 15, 0, 0,
    "", true, createNewSectionId());

export function getdefaultSectionObject() {
    let section = cloneDeep(defaultSectionObject);
    section.id = createNewSectionId();
    section.displayName = "";
    section.isPremade = false;
    return section;
}

const videoSection = createSectionObject(
    true, SORT_BY_UPVOTES, [getVideoFilterObject()], 15, 0, 0,
    "Videos", false, createNewSectionId());

export function getVideoSection() {
    let section = cloneDeep(videoSection);
    section.id = createNewSectionId();
    section.isPremade = false;
    return section;
}

const imageSection = createSectionObject(
    true, SORT_BY_UPVOTES, [getImageFilterObject()], 15, 0, 0,
    "Images", false, createNewSectionId());

export function getImagesSection() {
    let section = cloneDeep(imageSection);
    section.id = createNewSectionId();
    section.isPremade = false;
    return section;
}
const streamingSection = createSectionObject(
    true, SORT_BY_UPVOTES, [getStreamingFilterObject()], 15, 0, 0,
    "Streaming", false, createNewSectionId());

export function getStreamingSection() {
    let section = cloneDeep(streamingSection);
    section.id = createNewSectionId();
    section.isPremade = true;
    return section;
}


export const days_of_week = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
export const hours_of_day = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

export function getRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)]
}

// DEFAULT SUBREDDIT
export const defaultSubredditConfig = createSubredditConfigObject("", [], DELIVER_BY_REDDIT,
    false, getRandomItem(hours_of_day), getRandomItem(days_of_week));

export function getDefaultSubredditConfigObject(subreddit) {
    let config = cloneDeep(defaultSubredditConfig);
    let topPostsSection = getdefaultSectionObject();
    topPostsSection.displayName = "Top 15 Posts"
    let mostCommentedSection = createSectionObject(true, SORT_BY_COMMENTS, [], 10,
        0, 0, "", true, createNewSectionId());
    // set isDefault to false, bc if the user presses edit then discard changes, we don't want the section to be deleted
    topPostsSection.isPremade = true;
    mostCommentedSection.isPremade = true;
    config.configSections = [topPostsSection, mostCommentedSection];
    if (subreddit) {
        config.subreddit = subreddit;
    }
    return config;
}

export function createComboFilter(filterList, filterType) {
    filterList = filterList.filter((f) => f !== undefined);

    // if one of the children is the same as filterType - consolidate by adding all the other children to that child
    let newParent = filterList.find((filter) => {
        return filter.filterType === filterType;
    });
    if (newParent) {
        let newChildren = filterList.filter((f) => {
            return f.id !== newParent.id;
        });
        // find any newChildren that are also the same filter type
        let sameTypeChildren = newChildren.filter((nc) => {
            return nc.filterType === filterType;
        });
        if (sameTypeChildren) {
            // add the grand children (children of the newChildren that are the same filter type) to filterList
            let sameTypeGrandChildren = sameTypeChildren.reduce((accumulator, nextChild) => {
                accumulator.push(...nextChild.filterList);
                return accumulator;
            }, [])
            newParent.filterList.push(...sameTypeGrandChildren);
        }

        let diffTypeChildren = newChildren.filter((nc) => {
            return nc.filterType !== filterType;
        });
        newParent.filterList.push(...diffTypeChildren);
        return newParent;
    }

    return {filterType: filterType, filterList: filterList, id: createNewFilterId(), objectType: TYPE_COMBO_FILTER}
}

export function createSubredditConfigObject(subreddit, configSections, deliveryMethod, daily, mailTime, mailDay) {
    if (mailTime === 24) {
        mailTime = 0;
    }
    if (daily === "true") {
        mailDay = "daily";
    }
    // noinspection UnnecessaryLocalVariableJS
    let x = {
        subreddit: subreddit, configSections: configSections, deliveryMethod: deliveryMethod,
        daily: daily, mailTime: mailTime, mailDay: mailDay
    };
    return x;
}

export function createSectionObject(searchForPosts, sortBy, filterList, postLimit, upvoteThreshold, awardThreshold,
                                    displayName, isDefaultName, id) {
    let obj = {
        searchForPosts: searchForPosts, sortBy: sortBy, filterList: filterList, postLimit: postLimit,
        upvoteThreshold: upvoteThreshold, awardThreshold: awardThreshold, displayName: displayName,
        isDefaultName: isDefaultName, id: id, isEditable: false, objectType: TYPE_SECTION, isPremade: false,
    };

    if (isDefaultName || !displayName || !displayName.trim()) {
        obj.displayName = createConfigSectionDisplayName(obj);
    }
    return obj;
}

export function createFilterObject(filterTitle, filterFlair, filterUrl, filterSelftext, keyphraseList,
                                   isNegated, id) {
    let obj = {
        filterTitle: filterTitle, filterFlair: filterFlair, filterUrl: filterUrl, filterSelftext: filterSelftext,
        keyphraseList: keyphraseList, isNegated: isNegated, id: id, objectType: TYPE_FILTER
    };
    obj.displayName = createFilterDisplayName(obj);
    return obj;
}

export function createFilterDisplayName(filterObject) {
    let filterTypes = [];
    if (filterObject.filterTitle) {
        filterTypes.push("Title");
    }
    if (filterObject.filterFlair) {
        filterTypes.push("Flair");
    }
    if (filterObject.filterUrl) {
        filterTypes.push("Url");
    }
    if (filterObject.filterSelftext) {
        filterTypes.push("Selftext");
    }
    let filterTypeStr = "";
    if (filterTypes && filterTypes.length > 0) {
        filterTypeStr = `[${filterTypes.join(" or ")}]`;
    }

    // create the display string for the key phrases
    let keyphraseStr = filterObject.keyphraseList.join(", ");
    let maxStrLength = 25;
    if (keyphraseStr.length > maxStrLength) {
        keyphraseStr = keyphraseStr.substring(0, maxStrLength) + "...";
    }

    // add '~' if the filter is negated
    let negateStr = "";
    if (filterObject.isNegated) {
        negateStr = "~NOT~ ";
    }
    return `${negateStr}${filterTypeStr}: ${keyphraseStr}`;
}

function createDisplayNameFromFilters(filterList) {
    let nameList = []
    for (let index in filterList) {
        let filter = filterList[index];
        let filterType = filter.filterType;

        if (filterType) {
            let x = createDisplayNameFromFilters(filter.filterList);
            nameList.push(...x);
        } else {
            let x = createFilterDisplayName(filter)
            nameList.push(x);
        }
    }
    return nameList;
}

export function createConfigSectionDisplayName(sectionObj) {

    if (sectionObj.filterList && sectionObj.filterList.length > 0) {
        let filterStrs = createDisplayNameFromFilters(sectionObj.filterList);
        // work your way down from .length to 1, trying to create a display name below 75 characters
        let i = filterStrs.length;
        let display = "";
        do {
            let display = filterStrs.slice(0, i).join(", ")
            if (i === filterStrs.length && display.length <= MATCHER_DISPLAY_NAME_MAX_LENGTH) {
                return display;
            } else if (display.length <= MATCHER_DISPLAY_NAME_MAX_LENGTH - 3) {
                return display + "...";
            }
            i--;
        } while (i >= 1)
        return display.slice(0, MATCHER_DISPLAY_NAME_MAX_LENGTH - 3) + "...";
    }
    let postOrComment = "Posts";
    if (!sectionObj.searchForPosts) {
        postOrComment = "Comments";
    }

    let final_str;

    if (sectionObj.upvoteThreshold > 0 && sectionObj.postLimit) {
        final_str = `Top ${sectionObj.postLimit} ${postOrComment.toLowerCase()} with ${sectionObj.upvoteThreshold}+ upvotes`;
    } else if (sectionObj.awardThreshold > 0) {
        final_str = `${postOrComment} with ${sectionObj.awardThreshold}+ awards`;
    } else if (sectionObj.upvoteThreshold > 0) {
        final_str = `${postOrComment} with ${sectionObj.upvoteThreshold}+ upvotes`;
    } else {
        final_str = `Top ${sectionObj.postLimit} ${postOrComment.toLowerCase()}`;
    }

    if (sectionObj.sortBy === SORT_BY_COMMENTS) {
        final_str = `Most commented ${postOrComment}`
    } else if (sectionObj.sortBy === SORT_BY_AWARDS) {
        final_str = `Most awarded ${postOrComment}`
    }
    if (final_str) {
        return final_str
    }
    // return `${subreddit} section ${existingSections.length + 1}`;
    return "Enter a display name"
}
export function getYoutubeId(url) {
    if (!url) {
        return null
    }
    let uObj = new URL(url)
    if (url.includes("youtu.be")) {
        return uObj.pathname.replace("/", "")
    } else if (url.includes("youtube.com")){
        if (uObj.searchParams.get("v") != null) {
            return uObj.searchParams.get("v")
        }
        if (uObj.searchParams.get("list") != null) {
            return uObj.searchParams.get("list")
        }
        logError("ERROR getting youtube id for url", url)
    }
    return null
}
export function createNewUuid() {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
}
export function removeSearchParamsFromUrl(url) {
    try {
        let u = new URL(url);
        if (u.search) {
            return url.replace(u.search, "")
        }
    } catch (e) {}

    return url;
}
export function getCryptoSecureRandom() {
    let array = new Uint32Array(3);
    window.crypto.getRandomValues(array);
    return array[0].toString() + array[1].toString() + array[2].toString();
}

export function createNewSectionId() {
    return "section_" + createNewUuid();
}

export function createNewFilterId() {
    return "filter_" + createNewUuid();
}

export function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// export const queueIcon = <MdQueueMusic/>
export const queueIcon = <MdAddToQueue/>
export const connectedIcon = <MdVerified/>
export const connectingIconList = [<GrConnect/>, <MdOutlineOutlet/>]
/**
 * spotify state sobject
 */
export const sss = {
    STAGE_ZERO_STATUS: new State("STAGE_ZERO",
        0,
        "Not Connected",
        <GrConnect/>),

    ACCESS_TOKEN_INVALID_STATUS: new State("ACCESS_TOKEN_INVALID",
        2, "Re-connect Spotify",
        <MdOutlineErrorOutline/>),

    ACCESS_TOKEN_VALID_STATUS: new State("ACCESS_TOKEN_VALID",
        2, "Connecting ...",
        connectingIconList ),

    // JS_LOADED: new State("JS_LOADED",
    //     3),

    PLAYER_INIT_STATUS: new State("PLAYER_INIT", 4,
        "Connecting ...",
        connectingIconList),

    CONNECTING_STATUS: new State("CONNECTING",
        6,
        "Connecting ...",
        connectingIconList), // <SiWebauthn/>

    FAILED_CONNECT_STATUS: new State("FAILED_CONNECT",
        6,
        "Failed to Connect",
        <MdOutlineErrorOutline/>),

    CONNECTED_STATUS: new State("CONNECTED",
        7,
        "Signing in ...",
        connectingIconList),

    READY_TO_PLAY_STATUS: new State("READY_TO_PLAY",
        8,
        "Connected",
        connectedIcon),

    REFRESHING_STATUS: new State("REFRESHING",
        6, "Refreshing", [<BiRefresh/>, <BiRefresh style = {{transform: 'rotate(90deg)'}} />]
    ),

    FAILED_REFRESH_STATUS: new State("FAILED_REFRESH",
        6,
        "Try refreshing the page",
        <MdOutlineErrorOutline/>),

    ATTEMPTING_PLAY_STATUS: new State("ATTEMPTING_PLAY",
        7,
        "Playing...",
        connectedIcon),

    SUCCESSFUL_PLAY_STATUS: new State("SUCCESSFUL_PLAY",
        9,
        "Playing",
        connectedIcon),

    FAILED_PLAY_STATUS: new State("FAILED_PLAY",
        6,
        "Failed to Play",
        <MdOutlineErrorOutline/>),

    ATTEMPTING_PAUSE_STATUS: new State("ATTEMPTING_PAUSE",
        8,
        "Pausing...",
        connectedIcon),

    SUCCESSFUL_PAUSE_STATUS: new State("SUCCESSFUL_PAUSE",
        9,
        "Paused",
        connectedIcon),

    FAILED_PAUSE_STATUS: new State("FAILED_PAUSE",
        8,
        "Failed to pause",
        <MdOutlineErrorOutline/>),
}
export const defaultConnectData = {
    status: sss.STAGE_ZERO_STATUS,
    jsLoaded: false,
    player: null,
    uri: null,
    lastMessageSequence: -1,

    deviceId: null,
    lastUpdateTime: null,
    accessToken: null,

    // apiState: apiStateDefault,
    // playerState: playerStateDefault,
    playerState: new CommonState(null, null, STATE_TYPE.PLAYER),
    nowPlaying: new CommonState(null, null, STATE_TYPE.NOW_PLAYING),

    // Do i need all of these variables:
    connectionAttemptCount: 0,
    refreshTokenCount: 0,
    reconnectCount: 0,
    failedPlayCount: 0,
    failedConnectionCount: 0,
    failedRefreshCount: 0,

    isNewSong: false,
    isPush: false,
    isNewContext: false,
    isLastIndex: false,
    contextMismatch: false,
    currentIndex: null,
    actionIndex: null,
}
